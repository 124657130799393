import { ErrorResponse, FieldError } from "../openapi/data-contracts";

export type CsisError = string | FieldError | object;

// a typechecker that checks if object is FieldError
export const isFieldError = (x: any): x is FieldError =>
  (x as FieldError).field !== undefined &&
  (x as FieldError).error !== undefined;

export const isCsisErrorResponse = (x: any): x is ErrorResponse =>
  (x as ErrorResponse).success === false &&
  (x as ErrorResponse).errors &&
  Array.isArray((x as ErrorResponse).errors) &&
  (x as ErrorResponse).errors.length > 0;

import { isValidDate } from "../utils";

export function getStartDate(
  date?: Date,
  startDate?: Date,
  canSelectRange?: boolean,
) {
  if (canSelectRange) {
    return isValidDate(startDate) ? startDate : new Date();
  } else {
    return date || new Date();
  }
}

export function getUtcMidnight(date?: Date | null): string | null {
  if (!date) {
    return null;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
  ).toISOString();
}

import CheckBox from "../CheckBox/CheckBox";
import Skeleton from "../Skeleton/Skeleton";
import * as types from "./types";

const GhostRows = <COLUMN_KEY extends string>({
  columns,
  isSelectable,
  hasSubElements,
  ghostRows = 10,
}: {
  columns?: types.ColumnInterface<COLUMN_KEY, string>[];
  isSelectable?: boolean;
  hasSubElements?: boolean;
  ghostRows?: number;
}) => {
  return (
    <>
      {Array.from({ length: ghostRows }, () => (
        <tr key={Math.random()} className="table__row">
          {isSelectable && (
            <td className="cell cell__checkbox-container">
              <CheckBox
                key={Math.random()}
                isDisabled
                isChecked={false}
                name={"ghost-checkbox"}
              />
            </td>
          )}
          {columns?.map((column) => {
            if (column.isHidden) {
              return null;
            }
            return (
              <td key={Math.random()} className="cell">
                <Skeleton />
              </td>
            );
          })}
          {hasSubElements && (
            <td className="cell">
              <Skeleton />
            </td>
          )}
        </tr>
      ))}
    </>
  );
};

export default GhostRows;

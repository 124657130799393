import classNames from "classnames";
import React from "react";
import { CSIS, RED } from "../../colors";

export interface BadgeInterface {
  label: number | string;
  color: RED | CSIS;
}

const Badge: React.FC<BadgeInterface> = ({ label, color }) => {
  const classes = classNames("badge", {
    "badge--red": color === "red",
    "badge--csis": color === "csis",
  });

  return (
    <div title={String(label)} className={classes}>
      <div>{label}</div>
    </div>
  );
};

export default Badge;

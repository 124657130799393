/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import React, { useState } from "react";
import Icon, { IconKind } from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { LARGE, SMALL } from "../../sizes";
import IconButton from "../IconButton/IconButton";

export type InputAction = { icon: IconKind; text: string; onClick: () => void };

export interface InputInterface {
  name: string;
  type?: "text" | "number" | "email" | "password" | "textarea" | "tel";
  isDisabled?: boolean;
  isReadonly?: boolean;
  placeholder?: string;
  value?: string | number | null;
  isFullWidth?: boolean;
  hasShadow?: boolean;
  list?: string; // used for datalist
  onChange?: (value: string, name: string) => void;
  isTransparent?: boolean;
  isSemiTransparent?: boolean;
  hasBorder?: boolean;
  tabIndex?: number;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  dataTestId?: string;

  // type !== textarea specific:
  size?: SMALL | LARGE;
  icon?: IconKind;
  actions?: InputAction[];
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onFocusTransform?: { isTransparent?: boolean; hasShadow?: boolean };
  isOptional?: boolean;

  // type === textarea specific:
  expandOnFocus?: boolean;
}

const Input: React.FC<InputInterface> = ({
  name,
  placeholder,
  type = "text",
  expandOnFocus,
  icon,
  size,
  list,
  autoFocus,
  value = "",
  isDisabled,
  isReadonly,
  isFullWidth,
  onChange,
  isTransparent,
  isSemiTransparent,
  hasBorder,
  dataTestId,
  onFocus,
  onBlur,
  hasShadow = true,
  tabIndex = 0,
  onFocusTransform,
  actions,
  onKeyPress,
  isOptional,
}) => {
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    if (onChange) {
      onChange(value, name);
    }
  };

  const classes = classNames("input", {
    "input--small": size === "small",
    "input--large": size === "large",
    "input--full-width": isFullWidth,
    "input--readonly": isReadonly,
    "input--disabled": isDisabled,
    "input--transparent": isTransparent,
    "input--semi-transparent": isSemiTransparent,
    "input--has-border": hasBorder,
    "input--no-shadow": !hasShadow,
    "input--focus-solid": onFocusTransform?.isTransparent === false,
    "input--focus-transparent": onFocusTransform?.isTransparent === true,
    "input--focus-no-shadow": onFocusTransform?.hasShadow === false,
    "input--focus-shadow": onFocusTransform?.hasShadow === true,
  });

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <div className={classes}>
      {type === "textarea" ? (
        <textarea
          id={name}
          disabled={isDisabled}
          placeholder={placeholder}
          aria-label={name}
          onChange={handleChange}
          tabIndex={tabIndex}
          data-test-id={dataTestId}
          required={!isOptional}
          readOnly={isReadonly}
          value={value || ""}
          onFocus={onFocus}
          onBlur={onBlur}
          className={
            expandOnFocus && typeof value === "string" && value.length === 0
              ? "input__textarea--not-expaned"
              : "input__textarea"
          }
        />
      ) : (
        <>
          {icon && <Icon size="big" kind={icon} />}
          <input
            id={name}
            tabIndex={tabIndex}
            data-test-id={dataTestId}
            value={value || ""}
            readOnly={isReadonly}
            required={!isOptional}
            disabled={isDisabled}
            list={list}
            aria-label={name}
            type={type === "password" && isPasswordShown ? "text" : type}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {isOptional && <div className="input__optional">Optional</div>}
          {type === "password" && (
            <div className="input__password-show">
              <IconButton
                isDisabled={isDisabled}
                icon={isPasswordShown ? "visibility" : "visibility_off"}
                type="text"
                tooltipText="Show/Hide Password"
                isCircle
                dataTestId={dataTestId + "-show-password-btn"}
                spacing="small"
                onButtonClick={() => {
                  setIsPasswordShown(!isPasswordShown);
                }}
              />
            </div>
          )}
          {actions && (
            <div className="input__actions">
              <Stack align="center">
                {actions.map((action) => (
                  <IconButton
                    isDisabled={isDisabled}
                    icon={action.icon}
                    type="text"
                    tooltipText={action.text}
                    isCircle
                    spacing="small"
                    onButtonClick={action.onClick}
                    key={action.text}
                  />
                ))}
              </Stack>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Input;

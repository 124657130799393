import React, { useState } from "react";
import { Button, Dialog, IconButton, Skeleton } from "../../atoms";
import { Stack } from "../../layout";

type Item = { title: string; content: React.ReactElement };

export interface StepperDialogInterface {
  title: string;
  items: Item[];
  isPending?: boolean;
  error?: string | null;
  onClose?: () => void;
  onChange?: (newIndex: number) => void;
  hasAddNewItem?: boolean;
  isNavigateButtonsVisible?: boolean;
}

const StepperDialog: React.FC<StepperDialogInterface> = ({
  title,
  items,
  onClose,
  isPending,
  error,
  onChange,
  hasAddNewItem = false,
  isNavigateButtonsVisible,
}) => {
  const [current, setCurrent] = useState<number>(0);

  function handleChange(newIndex: number) {
    setCurrent(newIndex);
    if (onChange) {
      onChange(newIndex);
    }
  }

  function handleSetPrevious() {
    handleChange((current - 1 + items.length) % items.length);
  }

  function handleSetNext() {
    handleChange((current + 1) % items.length);
  }

  return (
    <Dialog name="stepper-dialog" size="auto" spacing="none" isHeaderAbsolute>
      <div className={"stepper-dialog"}>
        <div className={"stepper-dialog__sidebar"}>
          <Stack isVertical gutterSize="large" isExpanded>
            <div className="f_csis f_large f_semibold">{title}</div>

            {isPending ? (
              <Stack align="stretch" isVertical>
                <Skeleton type="multiline" />
              </Stack>
            ) : error ? (
              <div className="f_red">{error}</div>
            ) : (
              <Stack isVertical isExpanded>
                {items.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleChange(index)}
                    className={
                      index === current
                        ? "stepper-dialog__sidebar__item stepper-dialog__sidebar__item--selected"
                        : "stepper-dialog__sidebar__item"
                    }
                  >
                    {item.title}
                  </div>
                ))}
                {hasAddNewItem && (
                  <Button
                    onButtonClick={() => {}}
                    name="stepperdialog-add-new-item-btn"
                    text="Add new"
                    type="text"
                    icon="add"
                  />
                )}
              </Stack>
            )}
          </Stack>
        </div>
        <div className={"stepper-dialog__content-container"}>
          <div className="stepper-dialog__content">
            <Stack align="stretch" isVertical gutterSize="large">
              <Stack align="center" justify="space-between">
                <div className="f_semibold f_large">
                  {items[current]?.title}
                </div>
                {onClose && (
                  <IconButton
                    icon="close"
                    size="large"
                    color="secondary"
                    onButtonClick={onClose}
                    isCircle
                    type="text"
                  />
                )}
              </Stack>
              {isPending ? (
                <Skeleton type="multiline" />
              ) : error ? (
                <div className="f_red">{error}</div>
              ) : (
                items[current]?.content
              )}
            </Stack>
          </div>
          {isNavigateButtonsVisible && (
            <div className="stepper-dialog__actions">
              <Stack justify="space-between" isExpanded>
                <Button
                  name="stepperdialog-prev-btn"
                  text="Previous"
                  type="text"
                  isDisabled={current === 0}
                  icon="left-arrow"
                  onButtonClick={handleSetPrevious}
                />
                <Button
                  name="stepperdialog-prev-btn"
                  text="Next"
                  type="text"
                  isDisabled={current === items.length - 1}
                  isIconAfterText
                  icon="right-arrow"
                  onButtonClick={handleSetNext}
                />
              </Stack>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default StepperDialog;

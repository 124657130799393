/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Stack } from "@csis.com/components";
import Button from "@csis.com/components/src/atoms/Button/Button";
import { DateRange } from "../types";
import * as utils from "../utils";
import { getStartDate, getUtcMidnight } from "./utils";

export interface CalendarViewInterface {
  range?: DateRange;
  date?: Date;
  canSelectRange?: boolean;
  onDateSelected: (value: string | [string, string]) => void;
  dataTestId?: string;
  maxDate?: Date | null;
  formatDate?: (date?: Date | null) => string;
}

export default function CalendarView({
  range,
  date,
  canSelectRange,
  onDateSelected,
  dataTestId,
  maxDate,
  formatDate,
}: CalendarViewInterface) {
  const [startDate, setStartDate] = useState<Date | null>(
    getStartDate(date, range?.[0], canSelectRange),
  );
  const [startDateUtc, setStartDateUtc] = useState<string | null>(
    getUtcMidnight(startDate),
  );

  const [endDate, setEndDate] = useState<Date | null>(
    range?.[1] && utils.isValidDate(range?.[1]) ? range?.[1] : null,
  );
  const [endDateUtc, setEndDateUtc] = useState<string | null>(
    getUtcMidnight(endDate),
  );

  const onDateRangeChange = (date: Date | [Date | null, Date | null]) => {
    if (canSelectRange && Array.isArray(date)) {
      const [start, end] = date;

      // always convert to UTC midnight here to avoid timezone issues
      // we only care for days/months either way and never for specific hours
      const startUtc = getUtcMidnight(start);
      const endUtc = getUtcMidnight(end);

      setStartDate(start || null);
      setEndDate(end || null);
      setStartDateUtc(startUtc || null);
      setEndDateUtc(endUtc || null);
    } else {
      if (utils.isValidDate(date)) {
        const dateUtc = getUtcMidnight(date);

        setStartDate(date);
        setStartDateUtc(dateUtc);
        if (dateUtc) {
          onDateSelected(dateUtc);
        }
      }
    }
  };

  const handleApplyDateRange = () => {
    if (startDateUtc && endDateUtc && new Date(endDateUtc) instanceof Date) {
      onDateSelected([startDateUtc, endDateUtc]);
    }
  };

  const handleFormatDate = (date: Date) => {
    if (formatDate) {
      return formatDate(date);
    } else {
      return date.toLocaleDateString("en-GB");
    }
  };

  const classes = classNames(
    "datepicker__calendarview datepicker__calendarview--csis",
    {
      "datepicker__calendarview--with-range": canSelectRange,
    },
  );

  return (
    <div className={classes} data-test-id={dataTestId}>
      <Stack isVertical align="center">
        <Stack align="center" gutterSize="normal">
          {/* @ts-ignore */}
          <DatePicker
            selected={startDate}
            inline
            onChange={onDateRangeChange}
            selectsStart
            selectsMultiple={undefined}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            selectsRange={canSelectRange || undefined}
            showYearDropdown
            maxDate={maxDate || undefined}
            yearDropdownItemNumber={10}
            scrollableYearDropdown
            dateFormatCalendar="MMMM"
          />
        </Stack>

        {canSelectRange && (
          <Stack align="center" gutterSize="big">
            <div className="f_csis f_semibold">
              {startDate ? handleFormatDate(startDate) : "Start Date"}
            </div>
            <div>-</div>
            <div className="f_csis f_semibold">
              {endDate ? (
                handleFormatDate(endDate)
              ) : (
                <>
                  {startDate && startDate < new Date() ? (
                    <Button
                      text="Select today"
                      size="small"
                      onButtonClick={() => {
                        setEndDate(new Date());
                        setEndDateUtc(getUtcMidnight(new Date()));
                      }}
                      name="select today button"
                    />
                  ) : (
                    "End Date"
                  )}
                </>
              )}
            </div>

            <Button
              name="calendarview-apply-btn"
              size="small"
              text="Apply"
              isDisabled={
                !Boolean(startDate && endDate && endDate instanceof Date)
              }
              onButtonClick={handleApplyDateRange}
              dataTestId={"calendarview-btn-apply"}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
}

import classNames from "classnames";
import React from "react";
import { NORMAL, SMALL, TINY } from "../../sizes";

export interface ListInterface {
  isTransparent?: boolean;
  isBorderless?: boolean;
  size?: TINY | SMALL | NORMAL;
  labelSize?: SMALL | NORMAL;
  dataTestId?: string;
  children: React.ReactNode;
}

const List: React.FC<ListInterface> = ({
  isTransparent,
  size,
  isBorderless,
  labelSize,
  dataTestId,
  children,
}) => {
  const classes = classNames("list", {
    "list--transparent": isTransparent,
    "list--borderless": isBorderless,
    "list--small": size === "small",
    "list--tiny": size === "tiny",
    "list--labelsize-small": labelSize === "small",
  });
  return (
    <ul className={classes} data-test-id={dataTestId}>
      {children}
    </ul>
  );
};

export default List;

import React, { useState } from "react";
import ListMenu from "@csis.com/components/src/atoms/ListMenu/ListMenu";
import ListMenuItem from "@csis.com/components/src/atoms/ListMenu/ListMenuItem";
import CalendarView from "./CalendarView/CalendarView";
import { DatePickerOption, DateRange, TimePeriod, timePeriods } from "./types";
import * as utils from "./utils";

export interface DatePickerListInterface {
  options: DatePickerOption[];
  value?: TimePeriod;
  canSelectRange?: boolean;
  canSelectCustomValue?: boolean;
  range?: DateRange;
  date?: Date;
  onDateSelected: (value: string | [string, string] | undefined) => void;
  opensFromRight?: boolean;
  opensFromTop?: boolean;
  maxDate?: Date | null;
  dataTestId?: string;
  parentName: string;
  formatDate?: (date?: Date | null) => string;
}

const DatePickerList: React.FC<DatePickerListInterface> = ({
  options,
  value,
  range,
  date,
  canSelectRange,
  onDateSelected,
  opensFromRight,
  opensFromTop,
  maxDate,
  dataTestId,
  formatDate,
  parentName,
}) => {
  const [isCalendarShown, setIsCalendarShown] = useState<boolean>(
    value === timePeriods.CUSTOM,
  );

  const handleDropdownOptionSelect = (value: TimePeriod) => {
    if (value === timePeriods.CUSTOM) {
      setIsCalendarShown(true);
    } else {
      const range = utils.getRangeFromTimePeriod(value);
      onDateSelected(
        range ? [range[0].toISOString(), range[1].toISOString()] : undefined,
      );
    }
  };

  return (
    <ListMenu
      opensFromTop={opensFromTop}
      opensFromRight={opensFromRight}
      direction="row"
      parentName={parentName}
    >
      {canSelectRange && (
        <div className="width_100pct">
          {options.map((option) => {
            return (
              <ListMenuItem
                isSelected={
                  option.value === value ||
                  (value === timePeriods.CUSTOM &&
                    option.value === timePeriods.CUSTOM &&
                    utils.isValidRange(range))
                }
                tooltipText={option.tooltipText}
                onClick={() => handleDropdownOptionSelect(option.value)}
                dataTestId={dataTestId + "-" + option.value}
                key={option.value}
              >
                {option.label}
              </ListMenuItem>
            );
          })}
        </div>
      )}

      {(isCalendarShown || !canSelectRange) && (
        <CalendarView
          dataTestId={dataTestId + "-calendarview"}
          onDateSelected={onDateSelected}
          range={range}
          canSelectRange={canSelectRange}
          date={date}
          formatDate={formatDate}
          maxDate={maxDate}
        />
      )}
    </ListMenu>
  );
};

export default DatePickerList;

import { isString } from "@csis.com/tip/src/models/helpers";
import { CsisError, isFieldError } from "./models/ErrorResponse";

export function removeDuplicateErrors(errors: CsisError[]) {
  return errors.filter(
    // value, index, array
    (v, i, a) =>
      isString(v) ||
      (!isString(v) &&
        isFieldError(v) &&
        a.findIndex(
          (v2) => !isString(v2) && isFieldError(v2) && v2.field === v.field,
        ) === i),
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  ServiceSubscriptionRead,
  ServiceSubscriptionReadListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";

type ChildServiceParams = {
  externalId: string;
  organization_id: string;
};

interface StateSlice {
  [parentServiceId: string]: {
    data: ServiceSubscriptionRead[] | undefined;
    isDataPending: boolean;
    dataFetchError: string | null;
  };
}

const initialState: StateSlice = {};

const childServiceSubsctiptionsSlice = createSlice({
  name: "childServiceSubsctiptions",
  initialState: initialState,
  reducers: {
    fetchChildServiceSubscriptions(_, __: PayloadAction<ChildServiceParams>) {
      //empty handled by saga
    },
    setIsPending(state, action: PayloadAction<string>) {
      const parentServiceId = action.payload;

      state[parentServiceId] = {
        ...state[parentServiceId],
        isDataPending: true,
        dataFetchError: null,
        data: state[parentServiceId]?.data || undefined,
      };
    },
    setFetchError(
      state,
      action: PayloadAction<{ parentServiceId: string; errorMessage: string }>,
    ) {
      state[action.payload.parentServiceId] = {
        ...state[action.payload.parentServiceId],
        isDataPending: false,
        dataFetchError: action.payload.errorMessage,
        data: undefined,
      };
    },
    setFetchSuccess(
      state,
      action: PayloadAction<{
        parentServiceId: string;
        children: ServiceSubscriptionRead[];
      }>,
    ) {
      state[action.payload.parentServiceId] = {
        ...state[action.payload.parentServiceId],
        isDataPending: false,
        dataFetchError: null,
        data: action.payload.children,
      };
    },
  },
});

export default childServiceSubsctiptionsSlice.reducer;

export const {
  fetchChildServiceSubscriptions,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = childServiceSubsctiptionsSlice.actions;

// Async stuff - sagas

function* fetchChildServiceSubscriptionsSaga(
  action: PayloadAction<ChildServiceParams>,
) {
  const query = {
    ...action.payload,
  };

  yield put(setIsPending(action.payload.externalId));
  try {
    const response: AxiosResponse<ServiceSubscriptionReadListResponse> =
      yield call(
        axiosCsisApi.getChildServiceSubscriptionsApi10InternalServiceSubscriptionBaseExternalIdChildrenGet,
        query,
      );

    yield put(
      setFetchSuccess({
        parentServiceId: action.payload.externalId,
        children: response.data.payload,
      }),
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(
      setFetchError({
        parentServiceId: action.payload.externalId,
        errorMessage: errorMessage,
      }),
    );
  }
}

function* actionWatcher() {
  yield takeEvery(
    fetchChildServiceSubscriptions.toString(),
    fetchChildServiceSubscriptionsSaga,
  );
}

export function* childServiceSubscriptionsSagas() {
  yield all([actionWatcher()]);
}

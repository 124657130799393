import React from "react";
import {
  FormLabel,
  Input,
  ListItem,
  ListItemLabel,
  ListItemValue,
  Stack,
} from "@csis.com/components";

// A Standard parametrized text input field with label

interface BasicTextInputProps {
  label: string;
  isListItem?: boolean;
  name: string;
  type?: "text" | "number" | "textarea" | "password" | "tel" | "email";
  placeholder?: string;
  value?: string | number;
  isOptional?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  onChange: (value: string, name: string) => void;
  dataTestId?: string;
}

const BasicTextInput: React.FC<BasicTextInputProps> = ({
  label,
  name,
  isListItem,
  type = "text",
  placeholder,
  isOptional,
  isDisabled,
  isReadonly,
  value,
  onChange,
  dataTestId,
}) => {
  const formLabel = <FormLabel text={label} htmlFor={name} />;
  const input = (
    <Input
      name={name}
      type={type}
      isOptional={isOptional}
      isDisabled={isDisabled}
      isReadonly={isReadonly}
      isFullWidth
      size={isListItem ? "small" : undefined}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      dataTestId={dataTestId ? dataTestId : name}
    />
  );
  return isListItem ? (
    <ListItem verticalAlign="center">
      <ListItemLabel>
        <Stack align="center">{formLabel}</Stack>
      </ListItemLabel>
      <ListItemValue>{input}</ListItemValue>
    </ListItem>
  ) : (
    <Stack isVertical>
      {formLabel}
      {input}
    </Stack>
  );
};

export default BasicTextInput;

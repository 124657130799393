import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  OrganizationInternalRead,
  OrganizationReadPageDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { QueryParams } from "./types";

const customersSlice = createAsyncArrayFetchSlice<
  OrganizationInternalRead,
  QueryParams
>("customers");

export default customersSlice.reducer;

export const {
  fetchData: fetchCustomers,
  setIsPending: setIsCustomersFetchPending,
  setFetchError: setCustomersFetchError,
  setFetchSuccess: setFetchCustomersSuccess,
  setHasNextPage: setCustomersHasNextPage,
} = customersSlice.actions;

// Async stuff - sagas

function* fetchCustomersSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setIsCustomersFetchPending());

  try {
    const response: AxiosResponse<OrganizationReadPageDetailResponse> =
      yield call(
        axiosCsisApi.getOrganizationsApi10InternalOrganizationGet,
        action.payload,
        STRINGIFY_QUERY_PARAMS,
      );
    yield put(setFetchCustomersSuccess(response.data.payload.page));
    yield put(setCustomersHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setCustomersFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCustomers.toString(), fetchCustomersSaga);
}

export function* customersSagas() {
  yield all([actionWatcher()]);
}

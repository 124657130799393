import {
  getFirstDayOfPreviousMonthMidnightUTC,
  getFiveYearsAgoAgoMidnightUTC,
  getLastDayOfPreviousMonthMidnightUTC,
  getThirtyDaysAgoMidnightUTC,
  getTomorrowMidnightUTC,
} from "@csis.com/components/src/atoms/DatePicker/utils";
import { twoDigits } from "@csis.com/tip/src/components/shared/CountdownTimer/utils";

export type TimeDifferenceInfo = {
  amount: number;
  unit: Intl.RelativeTimeFormatUnit;
};

// gets a dateString like "2021-04-20T18:25:43.511Z"
// and a date for now - this argument is optional and is used only in tests to mock the "now"
// if its beyond 30 days in the past -> returns null
export function getRelativeTimeDifference(
  dateString: string,
  now: Date = new Date(),
): TimeDifferenceInfo | null {
  const date = new Date(dateString);

  // The number of milliseconds in one second
  const ONE_SECOND = 1000;
  // The number of milliseconds in one minute
  const ONE_MINUTE = ONE_SECOND * 60;
  // The number of milliseconds in one hour
  const ONE_HOUR = ONE_MINUTE * 60;
  // The number of milliseconds in one day
  const ONE_DAY = ONE_HOUR * 24;
  // The number of milliseconds in 30 days
  const THIRTY_DAYS = ONE_DAY * 30;

  // if for some reason the date is from the "future" return the dateString
  if (now.valueOf() < date.valueOf()) {
    return null;
  }

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(now.valueOf() - date.valueOf());

  let timeDifferenceInfo: TimeDifferenceInfo | undefined;

  if (differenceMs < ONE_MINUTE) {
    timeDifferenceInfo = {
      amount: Math.round(differenceMs / ONE_SECOND),
      unit: "seconds",
    };
  } else if (differenceMs < ONE_HOUR) {
    timeDifferenceInfo = {
      amount: Math.round(differenceMs / ONE_MINUTE),
      unit: "minutes",
    };
  } else if (differenceMs < ONE_DAY) {
    timeDifferenceInfo = {
      amount: Math.round(differenceMs / ONE_HOUR),
      unit: "hours",
    };
  } else if (differenceMs < THIRTY_DAYS) {
    timeDifferenceInfo = {
      amount: Math.round(differenceMs / ONE_DAY),
      unit: "days",
    };
  }

  return timeDifferenceInfo
    ? {
        ...timeDifferenceInfo,
        // amount is set negative because we use it for timestamps in the past. ex: 2 days ago
        amount: -timeDifferenceInfo.amount,
      }
    : null;
}

// returns an ISO datestring always set to midnight
export function getIsoDateString(date: Date) {
  if (!date || date.toString() === "Invalid Date") {
    return "";
  }
  const dateNow = date;
  return dateNow.toISOString();
}

// to be used as "end_date" in date filters when we want x last days etc
export function getTomorrowMidnightAsDateIsoString() {
  return getIsoDateString(getTomorrowMidnightUTC());
}

export function getOneMonthAgoAsDateIsoString() {
  return getIsoDateString(getThirtyDaysAgoMidnightUTC());
}

export function getFirstDayOfPreviousMonthAsDateIsoString() {
  return getIsoDateString(getFirstDayOfPreviousMonthMidnightUTC());
}

export function getLastDayOfPreviousMonthAsDateIsoString() {
  return getIsoDateString(getLastDayOfPreviousMonthMidnightUTC());
}

export function getFiveYearsAgoAsDateIsoString() {
  return getIsoDateString(getFiveYearsAgoAgoMidnightUTC());
}

// given 2 date strings, it returns the difference in days
export function calculateDiffBetweenTwoDatesInDays(
  startDateString?: string,
  endDateString?: string,
) {
  if (startDateString && endDateString) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(endDate.valueOf() - startDate.valueOf());

    return Math.floor(differenceMs / (24 * 60 * 60 * 1000));
  }

  return 0;
}

// given 2 date strings, it returns the difference in seconds
export function calculateDiffBetweenTwoDatesInSeconds(
  startDateString?: string,
  endDateString?: string,
) {
  if (startDateString && endDateString) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(endDate.valueOf() - startDate.valueOf());

    return Math.floor(differenceMs / 1000);
  }

  return 0;
}

export function secondsToDateHoursMinutesSecond(
  seconds?: number,
  stopAtZero: boolean = true,
) {
  if (!seconds || (seconds < 0 && stopAtZero)) {
    return "00:00:00";
  }

  const absSeconds = Math.abs(seconds);
  const d = Math.floor(absSeconds / (3600 * 24));
  const h = Math.floor((absSeconds % (3600 * 24)) / 3600);
  const m = Math.floor((absSeconds % 3600) / 60);
  const s = Math.floor(absSeconds % 60);

  const signDisplay = seconds < 0 ? "-" : "";
  const dDisplay = d > 0 ? d + "d " : "";
  const hDisplay = twoDigits(h);
  const mDisplay = twoDigits(m);
  const sDisplay = twoDigits(s);
  return signDisplay + dDisplay + hDisplay + ":" + mDisplay + ":" + sDisplay;
}

// given a Date Selection value
// depending on the case it tries to return a [Date,Date] tuple
export function getStartAndEndDate(
  value: [string, string] | string | undefined,
): [string, string] | [undefined, undefined] {
  if (!value) {
    return [undefined, undefined];
  }

  // if its a single Date, theres no start and end date
  if (typeof value === "string") {
    return [value, value];
  }

  //a DateRange
  const startDate = value[0];
  const endDate = value[1];

  return [startDate, endDate];
}

export function formatUtcDate(utcDate: string) {
  // this function manipulates the date string to be more readable
  // does not initialize a Date object to avoid timezone issues!

  // remove T and Z from the string
  const date = utcDate.replace("T", " ").replace("Z", "");
  // remove timezone offset from the string if it is of the form +00:00
  const date2 = date.replace(/(\+|-)(\d{2}):(\d{2})/, "");
  //add UTC to the end of the string
  return date2 + " UTC";
}

import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  ActionApi10InternalConnectorActionPostParams,
  ConnectorActionsBody,
  ConnectorCreateDarktraceAiAnalystModel,
  ConnectorCreateDarktraceModelBreachesModel,
  ConnectorCreateSentinelModel,
  ConnectorCreateXDRModel,
  ConnectorExternalReadListResponse,
  ConnectorReadExternal,
  GetConnectorsApi10InternalConnectorGetParams,
  PostConnectorApi10InternalConnectorPostParams,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";

const connectorsSlice = createAsyncArrayFetchSlice<
  ConnectorReadExternal,
  GetConnectorsApi10InternalConnectorGetParams
>("connectors");

export const connectorsReducer = connectorsSlice.reducer;

export const {
  fetchData: fetchConnectors,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = connectorsSlice.actions;

export type PostConnectorData =
  | ConnectorCreateXDRModel
  | ConnectorCreateSentinelModel
  | ConnectorCreateDarktraceModelBreachesModel
  | ConnectorCreateDarktraceAiAnalystModel;

const modifyConnectorsSlice = createAsyncDataUpdateSlice<string, undefined>(
  "modifyConnectors",
);

export const modifyConnectorsReducer = modifyConnectorsSlice.reducer;

export const {
  updateData: modifyConnectors,
  setIsUpdatePending: setIsModifyConnectorsPending,
  setIsUpdateError: setIsModifyConnectorsError,
  setIsUpdateSuccess: setIsModifyConnectorsSuccess,
  resetState: resetModifyConnectorsState,
} = modifyConnectorsSlice.actions;

const postConnectorsActionsSlice = createAsyncDataUpdateSlice<
  string,
  undefined
>("connectorsActions");

export const postConnectorsActionsReducer = postConnectorsActionsSlice.reducer;

export const {
  updateData: postConnectorsActions,
  setIsUpdatePending: setIsPostConnectorsActionsPending,
  setIsUpdateError: setIsPostConnectorsActionsError,
  setIsUpdateSuccess: setIsPostConnectorsActionsSuccess,
  resetState: resetPostConnectorsActions,
} = postConnectorsActionsSlice.actions;

// Async stuff - sagas

function* fetchConnectorsSaga(
  action: PayloadAction<GetConnectorsApi10InternalConnectorGetParams>,
) {
  yield put(setIsPending());

  try {
    const response: AxiosResponse<ConnectorExternalReadListResponse> =
      yield call(
        axiosCsisApi.getConnectorsApi10InternalConnectorGet,
        action.payload,
      );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* modifyConnectorsSaga(
  action: PayloadAction<{
    query: PostConnectorApi10InternalConnectorPostParams;
    data: PostConnectorData;
  }>,
) {
  yield put(setIsModifyConnectorsPending());

  try {
    yield call(
      axiosCsisApi.postConnectorApi10InternalConnectorPost,
      action.payload.query,
      action.payload.data,
    );

    yield put(setIsModifyConnectorsSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsModifyConnectorsError(errorMessage));
  }
}

function* postConnectorsActionsSaga(
  action: PayloadAction<{
    query: ActionApi10InternalConnectorActionPostParams;
    data: ConnectorActionsBody;
  }>,
) {
  yield put(setIsPostConnectorsActionsPending());

  try {
    yield call(
      axiosCsisApi.actionApi10InternalConnectorActionPost,
      action.payload.query,
      action.payload.data,
    );

    yield put(setIsPostConnectorsActionsSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsPostConnectorsActionsError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchConnectors.toString(), fetchConnectorsSaga);
  yield takeLatest(modifyConnectors.toString(), modifyConnectorsSaga);
  yield takeLatest(postConnectorsActions.toString(), postConnectorsActionsSaga);
}

export function* connectorsSagas() {
  yield all([actionWatcher()]);
}
